<template>
    <CDropdown
            inNav
            class="c-header-nav-items"
            placement="bottom-end"
    >
        <template #toggler>
            <CHeaderNavLink>
                <div class="c-avatar">
                    <img :src="$store.state.user.data.photoURL" class="c-avatar-img"/>
                </div>
            </CHeaderNavLink>
        </template>
      <CDropdownItem :href="'//' + $store.state.user.permission.Domain" target="_blank">
        <CIcon name="cil-globe-alt"/>
        {{ $t('Navigation.Personal/Website') }}
      </CDropdownItem>
        <CDropdownItem to="/profile">
            <CIcon name="cil-user"/>
            {{ $t('Navigation.Personal/Profile') }}
        </CDropdownItem>
        <CDropdownDivider/>
        <CDropdownItem @click="Logout()">
            <CIcon name="cil-lock-locked"/>
            {{ $t('Navigation.Personal/Logout') }}
        </CDropdownItem>
    </CDropdown>
</template>

<script>
    export default {
        name: 'TheHeaderDropdownAccnt',
        data() {
            return {
                itemsCount: 42
            }
        },
        methods: {
            Logout() {
              this.$router.replace('/logout').then(() => {
                this.$store.dispatch('Logout').then(() => {
                  this.$notify({
                    group: 'notify',
                    title: this.$t('Message.Success'),
                    text: this.$t('Message.Login/Logout'),
                    type: 'success'
                  })
                }).catch((err) => {
                  this.$notify({
                    group: 'notify',
                    title: this.$t('Message.Error'),
                    text: this.$t('Message.Login/Unexpected') + ': ' + err,
                    type: 'error'
                  })
                })
              })
            }
        }
    }
</script>

<style scoped>
    .c-icon {
        margin-right: 0.3rem;
    }
</style>
